<template>
	<div>
		<ContentHeader title="Laporan Jasa Medis" subTitle="Rekapitulasi Fee Dokter" url="laporan-jasa-medis" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title">Data Pasien</h3>
          </div>
          <div class="card-body" style="display: block;">
          	<div class="row" v-if="toogleCabang">
          		<div class="col-lg-4">
          			<label>Pilih Cabang</label>
          			<multiselect 
				          v-model="cabang_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama"
				          label="nama"
				          placeholder=""
				          :options="cabang"
				          :loading="loading_cabang"
				          :allow-empty="false"
				          @update:model-value="getDataResult"
				        >
				        </multiselect>
          		</div>
          	</div>
            <div class="row align-items-center mt-2">
              <div class="col-lg-2">
      					<span>Periode Awal</span>
      					<input type="date" class="form-control" v-model="periode_awal">
      				</div>
      				<div class="col-lg-2">
      					<span>Periode Akhir</span>
      					<input type="date" class="form-control" v-model="periode_akhir">
      				</div>
      				<div class="col-lg-1 pt-4">
      					<button class="btn btn-primary" @click="getDataResult">Filter</button>
      				</div>
              <div class="col-lg-2"></div>
              <div class="col-lg-5">
              	<span>Dokter</span>
              	<multiselect 
				          v-model="dokter_select_value"
				          deselect-label="Can't remove this value"
				          track-by="nama_lengkap"
				          label="nama_lengkap"
				          placeholder=""
				          :custom-label="customSelectDokter"
				          :options="dokter"
				          :loading="loading_dokter"
				          :allow-empty="false"
				          @update:model-value="getDataResult"
				        >
				        </multiselect>
              </div>
            </div>
            <table class="table table-bordered table-hover mt-4" v-if="memuat_data == false && cek_data == ''">
              <thead>
                <tr><th scope="col" class="text-sm">NO</th>
                  <th scope="col" class="text-sm">KETERANGAN</th>
                  <th scope="col" class="text-sm">JUMLAH</th>
                  <th scope="col" class="text-sm">TOTAL FEE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-sm font-weight-bold" scope="row">1</td>
                  <td class="text-sm font-weight-bold" scope="row">Treatment</td>
                  <td class="text-sm font-weight-bold" scope="row"></td>
                  <td class="text-sm font-weight-bold" scope="row">{{ format_nominal(tindakan) }}</td>                  
                </tr>
                <tr>
                  <td class="text-sm" scope="row"></td>
                  <td class="text-sm" scope="row">
                    <table class="table-borderless">
                      <tr v-for="row in data_result" :key="row.id">
                        <td>{{ row.tindakan.nama }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm" scope="row">
                    <table class="table-borderless">
                      <tr v-for="row in data_result" :key="row.id">
                        <td>{{ format_nominal(row.tindakan.total_fee) }}</td>
                      </tr>
                    </table>
                  </td>
                  <td class="text-sm" scope="row"></td>                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-bold" scope="row">2</td>
                  <td class="text-sm font-weight-bold" scope="row">Produk</td>
                  <td class="text-sm font-weight-bold" scope="row"></td>
                  <td class="text-sm font-weight-bold" scope="row">{{ format_nominal(produk) }}</td>                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-bold" scope="row">3</td>
                  <td class="text-sm font-weight-bold" scope="row">Uang Duduk</td>
                  <td class="text-sm font-weight-bold" scope="row"></td>
                  <td class="text-sm font-weight-bold" scope="row">{{ format_nominal(uang_duduk) }}</td>                  
                </tr>
                <tr>
                  <td class="text-sm font-weight-bold" scope="row">4</td>
                  <td class="text-sm font-weight-bold" scope="row">Konsultasi</td>
                  <td class="text-sm font-weight-bold" scope="row"></td>
                  <td class="text-sm font-weight-bold" scope="row">{{ format_nominal(konsultasi) }}</td>                  
                </tr>
                <tr v-if="total != ''">
                	<td class="font-weight-bold text-center" colspan="3">JUMLAH PERAWATAN REGULER</td>
                	<td class="font-weight-bold text-center">{{ format_nominal(total) }}</td>
                </tr>
              </tbody>
            </table>
            <p class="text-center mt-2" v-if="memuat_data">Memuat Data...</p>
            <p class="text-center mt-2">{{ cek_data }}</p>
          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          </div>
          <!-- /.card-footer-->
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader2'
import Multiselect from '@suadelabs/vue3-multiselect'

import { ref, computed, onMounted } from 'vue'
import store from '@/store'
import axios from 'axios'
import format_nominal from '@/format_nominal'

export default{
  components: {
    ContentHeader,
    Multiselect
  },
  setup(){
    const user = computed(() => store.getters['auth/user'])
    const data_result = ref([])
    const cek_data = ref('')
    const memuat_data = ref(false)
    const periode_awal = ref('')
    const periode_akhir = ref('')
    const uang_duduk = ref('')
    const tindakan = ref('')
    const konsultasi = ref('')
    const produk = ref('')
    const total = ref('')


    const getDataResult = async () => {
      data_result.value = []
      uang_duduk.value = ''
      tindakan.value = ''
      konsultasi.value = ''
      produk.value = ''
      total.value = ''
      memuat_data.value = true
      cek_data.value = ''
      let dokter_id = ''
			let tanggal_awal = ''
      let tanggal_akhir = ''
      let cabang_id = ''

      if (cabang_select_value.value == '') {
      	cabang_id = user.value.cabang_id
      	getDokter(user.value.cabang_id)
      } else {
      	cabang_id = cabang_select_value.value.id
      	getDokter(cabang_select_value.value.id)
      }

      if (periode_awal.value == '' && periode_akhir.value == '') {
      	tanggal_awal = 'emptyParameter'
      	tanggal_akhir = 'emptyParameter'
      } else {
      	tanggal_awal = periode_awal.value
      	tanggal_akhir = periode_akhir.value
      }

      if (dokter_select_value.value == '') {
      	dokter_id = 'emptyParameter'
      } else {
      	dokter_id = dokter_select_value.value.id
      }

      let { data } = await axios.get(`api/laporan/rekapitulasiFeeDokter/${cabang_id}/${tanggal_awal}/${tanggal_akhir}/${dokter_id}`)
      console.log(data)
      if (data == 'kosong') {
        cek_data.value = 'Data Masih Kosong'
        memuat_data.value = false
        data_result.value = []
        uang_duduk.value = ''
        tindakan.value = ''
        konsultasi.value = ''
        produk.value = ''
      } else if (data == 'choose_dokter') {
        cek_data.value = 'Pilih Dokter'
        memuat_data.value = false
        data_result.value = []
        uang_duduk.value = ''
        tindakan.value = ''
        konsultasi.value = ''
        produk.value = ''
      } else {
        memuat_data.value = false
        cek_data.value = ''
        data_result.value = data.tindakan_detail
        uang_duduk.value = data.uang_duduk
        tindakan.value = data.tindakan
        konsultasi.value = data.konsultasi
        produk.value = data.produk
        total.value = data.uang_duduk + data.tindakan + data.produk + data.konsultasi
        console.log(data_result.value)
      }
    }

		const toogleCabang = ref(false)
		const cabang_select_value = ref('')
		const cabang = ref([])
		const loading_cabang = ref(true)
		const getCabang = async () => {
			let response = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

			if (response.data.jenis == 'pusat') {
				toogleCabang.value = true
				let { data } = await axios.get(`api/cabang/getWhereCabang/${response.data.id}`)
				
				if (data != 'kosong') {
					cabang.value = data
					loading_cabang.value = false
				} else {
					cabang.value = []
					loading_cabang.value = false
				}
			}
		}

		const dokter = ref([])
		const dokter_select_value = ref('')
		const loading_dokter = ref(true)

		const customSelectDokter = ({ nama_lengkap }) => {
      if (nama_lengkap == 'Tanpa Konsultasi') {
        return `${nama_lengkap}`
      } else {
        return `Dr. ${nama_lengkap}`
      }
    }

		const getDokter = async (id) => {
			loading_dokter.value = true
			let { data } = await axios.get(`api/karyawan/getAllWhereJabatan/${id}/dokter`)

			if (data != 'kosong') {
				dokter.value = data
				loading_dokter.value = false
			} else {
				dokter.value = []
				loading_dokter.value = false
			}
		}


    onMounted(() => {
      getCabang()
      getDataResult()
    })

    return {
      data_result, cek_data, memuat_data, getDataResult, format_nominal, dokter, dokter_select_value, customSelectDokter, loading_dokter, total, periode_awal, periode_akhir, cabang_select_value, cabang, loading_cabang, toogleCabang, tindakan, uang_duduk, produk, konsultasi
    }
  }
}
</script>